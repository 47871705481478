<template>
    <!-- More info: https://en.gravatar.com/site/implement/images/ -->
    <v-avatar
        :size="size"
    >
        <v-img
            v-if="!customDataUri"
            :src="gravatarUrl"
            @error="onError($event)"
            loading="lazy"
        ></v-img>
        <img
            v-else
            class="fill-width fill-height"
            :src="customDataUri"
            loading="lazy"
        />
    </v-avatar>
</template>

<script lang="ts" setup>
    import { compact as lo_compact } from 'lodash-es';

    const CustomDataUri = () => useState<string | null>('keyAvatarCustomDataUri', () => null);

    const props = defineProps({
        size: {
            type: Number as PropType<number>,
            default: 32
        },
        avatarUrl: {
            type: String as PropType<string>,
            default: ''
        },
        name: {
            type: Array as PropType<string[]>,
            default: null
        },
        label: {
            type: String as PropType<Nilish<string>>,
            default: null
        }
    })

    const customDataUri = CustomDataUri();
    const initials = computed<string>(() => {
        let str = '🥍';
        const arr = lo_compact(props.name);

        if (arr.length) {
            if (arr.length > 2) {
                arr.splice(1, arr.length - 2);
            }

            str = arr.map((n) => n.charAt(0))
                .join('')
                .toUpperCase();
        } else if (props.label?.length) {
            str = props.label.charAt(0).toUpperCase();
        }

        return str;
    });
    const gravatarUrl = computed<string>(() => {
        const d = initials.value ? '404' : 'mp';
        return `${props.avatarUrl}?d=${d}&r=pg`;
    });

    function createAvatar(): string {
        const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
        <path fill="#000" d="M0 0h1000v1000H0z"/>
        <text x="50%" y="50%" dominant-baseline="central" text-anchor="middle" letter-spacing="0" font-size="500" fill="#fff" font-family="Arial">${initials.value}</text></svg>`;
        return `data:image/svg+xml,${encodeURIComponent(svg)}`;
    }

    function onError(e: string | undefined): void {
        if (initials.value) {
            customDataUri.value = createAvatar();
        }
    }
</script>

<style lang="scss" scoped></style>
